.hero-container {
  z-index: 2;
  width: 70%;
  line-height: 5.8rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}

.hero__content {
  color: #fff;
  text-align: center;
  padding-bottom: 8rem;
  font-size: 4.8rem;
}

.hero__text {
  text-transform: capitalize;
}

.hero__job {
  font-size: 2.4rem;
}

.social-container {
  justify-content: center;
  align-items: center;
  max-width: 20rem;
  font-size: 3.6rem;
  list-style: none;
  display: flex;
}

.social__list {
  transition: all .3s;
}

.social__list:link, .social__list:visited {
  backface-visibility: hidden;
  transition: all .3s;
  scale: 1;
}

.social__list:hover {
  transition: all .3s;
  scale: 1.3;
}

.social__list:active {
  transition: all .3s;
  transform: translate(.2rem, .2rem);
}

.social__link:link, .social__link:visited {
  color: var(--primary-color);
  cursor: pointer;
  backface-visibility: hidden;
  text-decoration: none;
  transition: all .3s;
}

.social__link:active {
  color: #00e4d8;
}

.hero__image-container {
  border: .5rem solid #fff;
  border-radius: 50%;
  width: 42rem;
  height: 42rem;
  overflow: hidden;
}

.hero__image {
  object-fit: cover;
  width: 100%;
}

/*# sourceMappingURL=index.d0ef7753.css.map */
