@import "variables.css";

.hero-container {
  position: absolute;
  z-index: 2;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  line-height: 5.8rem;
}

.hero__content {
  font-size: 4.8rem;
  color: white;
  text-align: center;
  padding-bottom: 8rem;
}

.hero__text {
  text-transform: capitalize;
}

.hero__job {
  font-size: 2.4rem;
}

/* Socials */

.social-container {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  font-size: 3.6rem;
}

.social__list {
  transition: all 0.3s;
}

.social__list:link,
.social__list:visited {
  backface-visibility: hidden;
  scale: 1;
  transition: all 0.3s;
}

.social__list:hover {
  scale: 1.3;
  transition: all 0.3s;
}

.social__list:active {
  transform: translate(0.2rem, 0.2rem);
  transition: all 0.3s;
}

.social__link:link,
.social__link:visited {
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.3s;
}

.social__link:active {
  color: #00e4d8;
}

.hero__image-container {
  width: 42rem;
  height: 42rem;
  border: solid 0.5rem white;
  border-radius: 50%;
  overflow: hidden;
}

.hero__image {
  width: 100%;
  object-fit: cover;
}
